























































































































































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component, Prop, Watch } from "vue-property-decorator";

import SessionControls from "./details/SessionControls.vue";
import TextAnalysisDetails from "@/components/sessions/details/TextAnalysisDetails.vue";
import AudioAnalysisDetails from "@/components/sessions/details/AudioAnalysisDetails.vue";
import SessionScoresCard from "@/components/sessions/cards/SessionScoresCard.vue";
import LocalStorage from "@/core/utils/LocalStorage";
import { UserClaims } from "@/core/models";

@Component({
  components: {
    AudioAnalysisDetails,
    TextAnalysisDetails,
    SessionControls,
    SessionScoresCard,
  },
})
export default class SessionDetails extends Vue {
  @Prop({ default: () => false }) enableFeedback!: boolean;
  @Getter("currentSession/id") id!: number;
  @Getter("currentSession/has") has!: boolean;
  @Getter("currentSession/loading") loading!: boolean;
  @Getter("currentSession/length") sessionLength!: number;
  @Getter("profile/showTutorial") showTutorial!: boolean;
  @Getter("sessions/gettingSingleSession") singleLoading!: boolean;
  @Getter("sessions/retrievedSessionId") retrievedSessionId!: number;
  @Getter("sessions/aiFeedbackMessage") aiFeedbackMessage!: string;
  @Getter("sessions/aiFeedbackLoading") aiFeedbackLoading!: boolean;
  @Getter("profile/userClaims") userClaims!: UserClaims[];
  @Action("audio/setTotalTime") setTotalTime!: Function;
  @Action("sessions/getAIFeedbackForSingleSession")
  getAIFeedbackForSingleSession!: (i: number) => Promise<void>;
  @Action("profile/fetchPermissions") fetchPermissions!: () => Promise<void>;

  analysisFast = this.analysisFastQuota || 0;
  analysisRelaxed = this.analysisRelaxedQuota || 0;
  aiFeedbackFast = this.aiFeedbackFastQuota || 0;
  aiFeedbackRelaxed = this.aiFeedbackRelaxedQuota || 0;

  get analysisFastQuota() {
    if (this.userClaims) {
      const quotaClaim = this.userClaims.filter(
        x => x.type === "analysis:fast",
      );
      if (quotaClaim[0]) {
        if (parseInt(quotaClaim[0].value as string, 10) === -1)
          return "unlimited";

        return parseInt(quotaClaim[0].value as string, 10);
      }
      return 0;
    }
    return 0;
  }

  get analysisRelaxedQuota() {
    if (this.userClaims) {
      const quotaClaim = this.userClaims.filter(
        x => x.type === "analysis:relaxed",
      );
      if (quotaClaim[0]) {
        if (parseInt(quotaClaim[0].value as string, 10) === -1)
          return "unlimited";

        return parseInt(quotaClaim[0].value as string, 10);
      }
      return 0;
    }
    return 0;
  }

  get aiFeedbackFastQuota() {
    if (this.userClaims) {
      const quotaClaim = this.userClaims.filter(
        x => x.type === "ai_feedback:fast",
      );
      if (quotaClaim[0]) {
        if (parseInt(quotaClaim[0].value as string, 10) === -1)
          return "unlimited";

        return parseInt(quotaClaim[0].value as string, 10);
      }
      return 0;
    }
    return 0;
  }

  get aiFeedbackRelaxedQuota() {
    if (this.userClaims) {
      const quotaClaim = this.userClaims.filter(
        x => x.type === "ai_feedback:relaxed",
      );
      if (quotaClaim[0]) {
        if (parseInt(quotaClaim[0].value as string, 10) === -1)
          return "unlimited";

        return parseInt(quotaClaim[0].value as string, 10);
      }
      return 0;
    }
    return 0;
  }

  @Watch("analysisFastQuota")
  updateAnalysisFast() {
    this.analysisFast = this.analysisFastQuota || 0;
  }
  @Watch("analysisRelaxedQuota")
  updateAnalysisRelaxed() {
    this.analysisRelaxed = this.analysisRelaxedQuota || 0;
  }
  @Watch("aiFeedbackFastQuota")
  updateAiFeedbackFast() {
    this.aiFeedbackFast = this.aiFeedbackFastQuota || 0;
  }
  @Watch("aiFeedbackRelaxedQuota")
  updateAiFeedbackRelaxed() {
    this.aiFeedbackRelaxed = this.aiFeedbackRelaxedQuota || 0;
  }

  isAIFeedbackOpen = false;

  VRMode = LocalStorage.getVRMode();

  getAIFeedback = (id: number) => {
    this.getAIFeedbackForSingleSession(id).then(async () => {
      await this.fetchPermissions();
      console.log("aiFeedbackMessage", this.aiFeedbackMessage);
      this.analysisFast = this.analysisFastQuota || 0;
      this.analysisRelaxed = this.analysisRelaxedQuota || 0;
      this.aiFeedbackFast = this.aiFeedbackFastQuota || 0;
      this.aiFeedbackRelaxed = this.aiFeedbackRelaxedQuota || 0;
    });
  };

  get isLoading() {
    return (
      this.loading ||
      (this.singleLoading && this.retrievedSessionId === this.id)
    );
  }

  @Watch("sessionLength", { immediate: true })
  onLengthUpdate() {
    if (this.sessionLength !== 0) this.setTotalTime(this.sessionLength);
  }

  mounted() {
    this.fetchPermissions();
    this.analysisFast = this.analysisFastQuota || 0;
    this.analysisRelaxed = this.analysisRelaxedQuota || 0;
    this.aiFeedbackFast = this.aiFeedbackFastQuota || 0;
    this.aiFeedbackRelaxed = this.aiFeedbackRelaxedQuota || 0;
  }
}
